<template>
  <b-card title="Quản lý học phí">
    <b-card-text />
    <b-row class="align-items-end mb-2">
      <b-col
        md="6"
        xl="3"
      >
        <h6>Tìm kiếm theo từ khoá</h6>
        <b-form-group class="mb-0">
          <input
            v-model="modelStudentManagement.keyWord"
            type="text"
            placeholder="Nhập từ khoá để tìm kiếm học phí"
            class="form-control"
          >
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
      >
        <h6>Cấp học</h6>
        <b-form-group class="mb-0">
          <v-select
            v-model="modelStudentManagementLevelSchoolsId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :disabled="checkLevel"
            :options="schoolLevel"
            placeholder="Chọn cấp học"
            :reduce="(option) => option.id"
            @input="getListClass"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
      >
        <h6>Khối</h6>
        <b-form-group class="mb-0">
          <v-select
            v-model="modelStudentManagement.classId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="optionClass"
            placeholder="Chọn khối"
            :reduce="(option) => option.id"
            :disabled="checkClassEntity"
          >
            <span slot="no-options">Không có dữ liệu.</span>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
      >
        <div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="onChangePagination(1)"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            <span class="align-middle">Tìm kiếm</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-6 col-xl-9">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :to="{ name: 'add-tuition'}"
          variant="outline-primary"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Thêm mới</span>
        </b-button>
      </b-col>
      <b-col class="col-md-6 col-xl-3" />
    </b-row>
    <div class="data-table-link mt-1">
      <div class="row">
        <div class="col-md-12">
          <div class="table-data-employee mt-1">
            <span>Có tổng số {{ totalRecordsList }} bản ghi</span>
            <b-table
              responsive
              :items="listStudent"
              :fields="fieldsTable"
              class="mb-0"
            >
              <template #cell(index)="data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template #cell(levelSchoolsId)="data">
                <label v-if="data.value === 1">
                  Cấp Tiểu học
                </label>
                <label v-if="data.value === 2">
                  Cấp THCS
                </label>
                <label v-if="data.value === 3">
                  Cấp THPT
                </label>
                <label v-if="data.value === 4">
                  Mầm non
                </label>
              </template>
              <template #cell(classEntity)="data">
                <label>
                  {{ (data.value.name) }} {{ (getInfoClass(data.value.classType)) }}
                </label>
              </template>
              <template #cell(hanhdong)="data">
                <b-button
                  v-b-tooltip.hover.top="'Cập nhật'"

                  variant="flat-primary"
                  class="btn-icon"
                  @click="getDetailStudent(data.item.id)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="'Xoá'"
                  variant="flat-danger"
                  class="btn-icon"
                  @click="deleteStudent(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <div class="pagination-custom mt-2 float-right" v-if="totalRecordsList > 0">
              <template>
                <b-pagination
                  v-model="currentPage"
                  :per-page="modelStudentManagement.pageSize"
                  :total-rows="totalRecordsList"
                  first-number
                  last-number
                  @change="onChangePagination"
                />
              </template>
            </div>
            <div class="mt-2 d-flex justify-content-center" v-else>
              <span class="text-secondary">Không có dữ liệu</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardText, BCol, BFormGroup, BPagination, BRow, BTable, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BButton,
    BTable,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      checkLevel: false,
      totalRecordsList: 0,
      records: 0,
      currentPage: 1,
      modelStudentManagement: {
        levelSchoolsId: null,
        classId: null,
        pageIndex: 0,
        pageSize: 20,
        keyWord: '',
      },
      fieldsTable: [
        {
          key: 'index', label: 'STT', thClass: 'customThIndexs',
        },
        {
          key: 'classEntity', label: 'Khối',
        },
        {
          key: 'levelSchoolName', label: 'Cấp học',
        },
        {
          key: 'tuition', label: 'Học phí tháng',
        },
        {
          key: 'totalMonth', label: 'Số tháng học',
        },
        {
          key: 'semesterDiscount', label: 'Giảm giá theo học kỳ (%)',
        },
        {
          key: 'yearDiscount', label: 'Giảm giá theo năm (%)',
        },
        {
          key: 'hanhdong', label: 'Hành động',
        },
      ],
      listStudent: [
      ],
      schoolLevel: [
        { name: 'Cấp Tiểu học', id: 1 },
        { name: 'Cấp THCS', id: 2 },
        { name: 'Cấp THPT', id: 3 },
      ],
      optionClass: [],
      checkClassEntity: true,
      modelStudentManagementLevelSchoolsId: null,
      levelSchoolIds: [],
    }
  },
  created() {
    const userCheckRole = JSON.parse(localStorage.getItem('dataMenuUser'))
    userCheckRole.map(x => {
      if (x.level.length > 1) {
        this.levelSchoolIds = x.level
        this.modelStudentManagementLevelSchoolsId = null
        this.checkLevel = false
        this.getListClass()
      }
      if (x.level.length === 1) {
        this.levelSchoolIds = x.level
        this.modelStudentManagementLevelSchoolsId = null
        this.checkLevel = true
        this.getListClass()
      }
    })
    this.getListAllStudent()
    this.getListSchool(userCheckRole)
  },
  methods: {
    getListSchool(userCheckRole) {
      this.$crm.get('level-school/all').then(res => {
        this.schoolLevel = res.data
        userCheckRole.map(x => {
          if (x.level.length > 0) {
            let obDTO = []
            res.data.forEach(el => {
              let data = x.level.find(x => x === el.id)
              if (data) obDTO.push(el)
            })
            this.schoolLevel = obDTO
          }
        })
      })
    },
    getInfoClass(classType) {
      switch (classType) {
        case 0:
          return 'STEAM'
        case 1:
          return 'CLC'
        case 2:
          return 'NKNN'
        case 4:
          return 'Steam A'
        case 5:
          return 'Steam D'
      }
    },
    getListClass() {
      if (this.modelStudentManagementLevelSchoolsId) {
        this.$crm.get(`class-entity/find-by-level-school-id/${this.modelStudentManagementLevelSchoolsId}`).then(res => {
          this.optionClass = res.data
          if (this.optionClass.length > 0) {
            this.checkClassEntity = false
            this.optionClass.forEach(item => {
              if (item.classType === 0) {
                item.name = `${item.name} STEAM`
              }
              if (item.classType === 1) {
                item.name = `${item.name} CLC`
              }
              if (item.classType === 2) {
                item.name = `${item.name} NKNN`
              }
              if (item.classType === 4) {
                item.name = `${item.name} Steam A`
              }
              if (item.classType === 5) {
                item.name = `${item.name} Steam D`
              }
              if (item.classType === 6) {
                item.name = `${item.name} CLC A`
              }
              if (item.classType === 7) {
                item.name = `${item.name} CLC D`
              }
            })
          } else {
            this.checkClassEntity = true
          }
        })
      } else {
        this.checkClassEntity = true
        this.modelStudentManagement.classId = null
        this.optionClass = []
      }
    },
    getListAllStudent() {
      this.modelStudentManagement.levelSchoolsId = this.modelStudentManagementLevelSchoolsId ? [this.modelStudentManagementLevelSchoolsId] : this.levelSchoolIds.length > 0 ? this.levelSchoolIds : null
      this.$crm.post('tuition-school/find-by-condition', this.modelStudentManagement).then(res => {
        if (res.data.listData && res.data.listData.length > 0) {
          this.listStudent = res.data.listData.map(q => {
            q.tuition = q.tuition.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            return q
          })
        } else {
          this.listStudent = []
        }
        this.totalRecordsList = res.data.totalRecords
        this.records = res.data.records
      })
    },
    deleteStudent(item) {
      let fullNameCv = null
      if (item.classEntity.classType === 0) {
        fullNameCv = `${item.classEntity.name} STEAM`
      }
      if (item.classEntity.classType === 1) {
        fullNameCv = `${item.classEntity.name} NKNN`
      }
      if (item.classEntity.classType === 2) {
        fullNameCv = `${item.classEntity.name} NKVD`
      }
      if (item.classEntity.classType === 4) {
        fullNameCv = `${item.classEntity.name} Steam A`
      }
      if (item.classEntity.classType === 5) {
        fullNameCv = `${item.classEntity.name} Steam D`
      }
      this.$swal({
        title: 'Xóa học phí',
        text: `Bạn có muốn xóa học phí ${fullNameCv}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.get(`tuition-school/delete/${item.id}`).then(res => {
            this.getListAllStudent()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Xóa học phí thành công',
              },
            },
            { timeout: 4000 })
          })
        }
      })
    },
    getDetailStudent(item) {
      this.$router.push({
        name: 'update-tuition',
        query: {
          id: item,
        },
      })
    },
    onChangePagination(currentPage) {
      this.modelStudentManagement.pageIndex = currentPage - 1
      this.getListAllStudent();
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
@import '../../assets/scss/add-student';
</style>
